import { onMounted, onBeforeUnmount, watch, nextTick } from 'vue';

import type BaseGridNavigation from 'o365.controls.Grid.extensions.BaseNavigation.ts';
import type BaseGridControl from 'o365.controls.Grid.BaseGrid.ts';
import type { Location, AreaSelection } from 'o365.modules.SelectionControl.ts';
type Ref<T> = {
    value: T
}

enum KeyCode {
    TAB = 'Tab',
    LEFT = 'ArrowLeft',
    UP = 'ArrowUp',
    RIGHT = 'ArrowRight',
    DOWN = 'ArrowDown',
    F2 = 'F2',
    ESC = 'Escape',
    ENTER = 'Enter',
    DEL = 'Delete',
    SPACE = ' ',
}

declare module 'o365.controls.Grid.BaseGrid.ts' {
    interface BaseGridControl {
        gridFocusControl: GridFocusControl;
        gridSelectionInterface: GridSelectionInterface;
    }
}


export interface NavigationControl {
    renderSelection?: (selection: AreaSelection) => void;
};

/**
 * Enables all navigation events and rendering 
 */
export default function useDataGridNavigation(options: {
    containerRef: Ref<HTMLElement>,
    gridControl: Ref<BaseGridControl>,
    rowSelector: string,
    cellSelector: string,
    forceEditMode?: boolean,
    widthScrollerRef?: Ref<HTMLElement>,
    getCellEditorRef?: Function,
    enableDrag?: boolean
    enableContextMenu?: boolean
    disableSelectionRendering: boolean
}, NavigationExtension: typeof BaseGridNavigation) {

    const { focusFirstEditableCell } = useKeyboardNavigation({
        containerRef: options.containerRef,
        gridControl: options.gridControl,
        rowSelector: options.rowSelector,
        cellSelector: options.cellSelector,
        forceEditMode: options.forceEditMode,
        widthScrollerRef: options.widthScrollerRef
    }, NavigationExtension);

    useSelectionMouseEvents({
        containerRef: options.containerRef,
        gridControl: options.gridControl,
        enableDrag: options.enableDrag,
        forceEditMode: options.forceEditMode,
        rowSelector: options.rowSelector,
        cellSelector: options.cellSelector,
        enableContextMenu: options.enableContextMenu,
    });

    let selectionRenderingObj: any;
    if (!options.disableSelectionRendering) {
        selectionRenderingObj = useSelectionRendering({
            containerRef: options.containerRef,
            gridControl: options.gridControl,
            rowSelector: options.rowSelector,
            cellSelector: options.cellSelector
        }, NavigationExtension);
    }

    useGridFocusRendering({
        containerRef: options.containerRef,
        gridControl: options.gridControl,
        rowSelector: options.rowSelector,
        cellSelector: options.cellSelector,
        getCellEditorRef: options.getCellEditorRef,
        forceEditMode: options.forceEditMode
    }, NavigationExtension);

    useAllowEditWatcher({
        gridControl: options.gridControl
    }, NavigationExtension);

    const navigationControl = <NavigationControl>{
        renderSelection: selectionRenderingObj?.renderSelection,
        focusFirstEditableCell: focusFirstEditableCell
    };

    return { navigationControl };
}

/**
 * Enables keyboard navigational/editing events
 */
export function useKeyboardNavigation(options: {
    containerRef: Ref<HTMLElement>,
    gridControl: Ref<BaseGridControl>,
    rowSelector: string,
    cellSelector: string,
    forceEditMode?: boolean,
    widthScrollerRef?: Ref<HTMLElement>
},   NavigationExtension: typeof BaseGridNavigation) {
    const containerRef = options.containerRef;
    const gridControl = options.gridControl;
    const gridFocusControl = GridFocusControl.getGridFocusControl(options.gridControl);
    const gridSelectionInterface = GridSelectionInterface.getGridSelectionInterface(options.gridControl);
    const navigation = NavigationExtension.extend(gridControl.value);

    function _isInEditMode() {
        return options.forceEditMode || gridFocusControl.editMode;
    }

    function _syncWidthScroll(cell: Location) {
        if (!cell || !options.widthScrollerRef) { return; }

        const column = gridControl.value.columns.columns[cell.x];
        if (column && !column.pinned) {
            const scrollContainer = options.widthScrollerRef.value.querySelector(".o365-body-horizontal-scroll-viewport");
            if (!scrollContainer) { return; }
            const left = column.left;
            if (scrollContainer.scrollLeft + scrollContainer.clientWidth < left + column.width) {
                scrollContainer.scrollLeft += (left + column.width) - (scrollContainer.scrollLeft + scrollContainer.clientWidth) + 1;
            } else if (left < scrollContainer.scrollLeft) {
                scrollContainer.scrollLeft = left;
            }
        }
    }

    function _scrollToCell(cell: Location) {
        if (!cell) { return; }

        if (!navigation.validRowIndex(cell.y)) {
            return false;
        }

        const column = gridControl.value.columns.columns[cell.x];
        if (!column || column.suppressNavigable) { return; }

        const cellY = cell.y * 34;
        if ((containerRef.value.clientHeight + containerRef.value.scrollTop) <= (cellY + 34)) {
            containerRef.value.scrollTop = cellY - containerRef.value.clientHeight + 33;
        } else if (cellY < containerRef.value.scrollTop) {
            containerRef.value.scrollTop = cellY === 0 ? 1 : cellY;
        }
    }

    function _isValidNavigationCell(cell: Location) {
        if (!cell) { return false; }
        const column = gridControl.value.columns.columns[cell.x];

        if (!column) { return false; }
        else if (_isInEditMode() && !column.editable) { return false; }
        else if (column.suppressNavigable || column.hide) { return false; }
        else if (!navigation.validRowIndex(cell.y)) { return false; }

        if (_isInEditMode()) {
            const valid = navigation.validEditRowIndex(cell.y);
            if (!valid) { return; }
        }

        return true;
    }

    function _isCellGoodToFocusOn(cell: Location) {
        const column = gridControl.value.columns.columns[cell.x];

        return column && !column.hide && !column.suppressNavigable && _cellNodeExists(cell);
    }

    function _setFocusToCell(cell: Location) {
        gridSelectionInterface.setSingleSelection(cell);
        gridFocusControl.setFoucsToCell(cell);
    }

    function _focusElementNode(cell: Location) {
        window.requestAnimationFrame(() => {
            const cellNode = <HTMLElement>containerRef.value.querySelector(getCellSelector(options.rowSelector, options.cellSelector, cell.y, cell.x));
            cellNode?.focus();
        });
    }

    function _isPotentialCell(cell: Location) {
        return navigation.isPotentialCell(cell);
    }

    function _cellNodeExists(cell: Location) {
        const cellNode = <HTMLElement>containerRef.value.querySelector(getCellSelector(options.rowSelector, options.cellSelector, cell.y, cell.x));
        return !!cellNode;

    }

    function __onShiftRangeSelect(e: KeyboardEvent, key: string, currentCell: Location) {
        let nextCell = currentCell;

        while (nextCell && (nextCell === currentCell || !_isValidNavigationCell(nextCell))) {
            if (e.ctrlKey) {
                //
                nextCell = null;
            } else {
                nextCell = _getNextCellToFocusWithoutCtrlPressed(key, nextCell);
            }
        }

        if (nextCell) {
            gridSelectionInterface.setAreaEnd(nextCell);
            _syncWidthScroll(nextCell);
        }
    }

    function _getCellAbove(fromCell: Location) {
        if (!fromCell) { return; }

        const cell = { x: fromCell.x, y: fromCell.y - 1 };
        // scrollToCell;
        _scrollToCell(cell);
        // check if node exists
        if (_cellNodeExists(cell)) {
            return cell;
        }
    }
    function _getCellBellow(fromCell: Location) {
        if (!fromCell) { return; }

        const cell = { x: fromCell.x, y: fromCell.y + 1 };
        // scrollToCell;
        // check if node exists
        _scrollToCell(cell);
        if (_cellNodeExists(cell)) {
            return cell;
        }
    }
    function _getCellToLeft(fromCell: Location) {
        if (!fromCell) { return; }

        const cell = { x: fromCell.x - 1, y: fromCell.y };
        // scrollToCell;
        if (_isPotentialCell(cell)) {
            return cell;
        }
    }

    function _getCellToRight(fromCell: Location) {
        if (!fromCell) { return; }

        const cell = { x: fromCell.x + 1, y: fromCell.y };
        //check
        //scroll
        if (_isPotentialCell(cell)) {
            return cell;
        }
    }

    function _getNextCellToFocusWithoutCtrlPressed(key: string, currentCell: Location) {
        let pointer = currentCell;
        let finished = false;

        while (!finished) {
            switch (key) {
                case KeyCode.UP:
                    pointer = _getCellAbove(pointer);
                    break;
                case KeyCode.DOWN:
                    pointer = _getCellBellow(pointer);
                    break;
                case KeyCode.RIGHT:
                    pointer = _getCellToRight(pointer);
                    break;
                case KeyCode.LEFT:
                    pointer = _getCellToLeft(pointer);
                    break;
                default:
                    pointer = null;
                    console.warn(`Unknown key for navigation: ${key}`);
            }

            if (pointer) {
                finished = _isCellGoodToFocusOn(pointer);
            } else {
                finished = true;
            }
        }

        return pointer;
    }

    function __navigateToNextCell(e: KeyboardEvent, key: string, currentCell: Location) {
        let nextCell = currentCell;

        while (nextCell && (nextCell === currentCell || !_isValidNavigationCell(nextCell))) {
            if (e.ctrlKey) {
                //
                nextCell = null;
            } else {
                nextCell = _getNextCellToFocusWithoutCtrlPressed(key, nextCell);
            }
        }

        if (!nextCell) { return; }
        _syncWidthScroll(nextCell);
        _setFocusToCell(nextCell);
    }

    function _onNavigationKeyDown(e: KeyboardEvent, key: string) {
        if (_isInEditMode()) { return; }

        e.preventDefault();
        if (e.shiftKey) {
            const cell = gridSelectionInterface.areaEnd;
            __onShiftRangeSelect(e, key, cell);
        } else {
            __navigateToNextCell(e, key, gridFocusControl.activeCellLocation);
        }
    }

    function _tabToNextCellColumn(fromCell: Location, backwards: boolean) {
        let nextCell = fromCell;

        while (nextCell && (nextCell === fromCell || !_isValidNavigationCell(nextCell))) {
            if (backwards) {
                nextCell = _getCellToLeft(nextCell);
            } else {
                nextCell = _getCellToRight(nextCell);
            }
        }

        if (nextCell) {
            _syncWidthScroll(nextCell);
            _setFocusToCell(nextCell);
            return true;
        } else {
            return false;
        }
    }

    function _getFirstRowCell(row: number, editableOnly = false) {
        const columns = gridControl.value.columns.columns;
        for (let i = 0; i < columns.length; i++) {
            const column = columns[i];
            if (!column.hide && !column.suppressNavigable && ((!_isInEditMode() && !editableOnly) || (_isInEditMode() && column.editable) || (editableOnly && column.editable))) {
                return { x: i, y: row }
            }
        }
    }


    function _getLastRowCell(row: number) {
        const columns = gridControl.value.columns.columns;
        for (let i = columns.length - 1; i >= 0; i--) {
            const column = columns[i];
            if (!column.hide && !column.suppressNavigable && (!_isInEditMode() || (_isInEditMode() && column.editable))) {
                return { x: i, y: row };
            }
        }
    }

    function _onTabKeyDown(e: KeyboardEvent) {
        const currentCell = gridFocusControl.activeCellLocation;
        const backwards = e.shiftKey;

        const movedToNextCellColumn = _tabToNextCellColumn(currentCell, backwards);

        if (!movedToNextCellColumn) {
            const increment = backwards ? -1 : 1
            let row = currentCell.y + increment;
            if (_isInEditMode()) {
                row = navigation.findClosestValidRowForInEditTab(row, increment, options.forceEditMode ?? false);
            }

            const cell = backwards
                ? _getLastRowCell(row)
                : _getFirstRowCell(row);

            if (_isValidNavigationCell(cell)) {
                _setFocusToCell(cell);
                _syncWidthScroll(cell);
                e.preventDefault();
            }
        } else {
            e.preventDefault();
        }
    }

    function _onEnterKeyDown(e: KeyboardEvent) {
        const backwards = e.shiftKey;

        const currentCell = gridFocusControl.activeCellLocation;

        let nextCell: Location;
        if (backwards) {
            nextCell = _getCellAbove(currentCell);
        } else {
            nextCell = _getCellBellow(currentCell);
        }

        if (nextCell && _isValidNavigationCell(nextCell)) {
            if (_isInEditMode()) {
                gridFocusControl.editMode = false;
                navigation.save();
            }

            _setFocusToCell(nextCell);
        }
    }

    function _onEscKeyDown(_e: KeyboardEvent) {
        const activeCell = gridFocusControl.activeCellLocation;
        navigation.handleEsc(activeCell);

        if (_isInEditMode()) {
            gridFocusControl.exitEditMode();
            _focusElementNode(activeCell);
        }
    }

    function _onF2KeyDown(_e: KeyboardEvent) {
        if (!_isInEditMode()) {
            const cell = gridFocusControl.activeCellLocation;
            _scrollToCell(cell);
            gridFocusControl.enterEditMode();
        }
    };

    function _onKeyDownWithCtrl(e: KeyboardEvent) {
        switch (e.key) {
            case 'c':
            case 'C':
                navigation.copy(e).then((success: boolean) => {
                    if (success) {
                        containerRef.value.querySelectorAll('.o365-cell-range-selected, .o365-cell-range-single-cell').forEach(cell => cell.classList.add('o365-cell-copy-highlight-animation'))
                        setTimeout(() => {
                            containerRef.value.querySelectorAll('.o365-cell-copy-highlight-animation').forEach(cell => cell.classList.remove('o365-cell-copy-highlight-animation'));
                        }, 1001)
                    }
                });
                return true;
            case 'v':
            case 'V':
                navigation.paste(e).then((success: boolean) => {
                    if (success) {
                        containerRef.value.querySelectorAll('.o365-cell-range-selected, .o365-cell-range-single-cell').forEach(cell => cell.classList.add('o365-cell-paste-highlight-animation'))
                        setTimeout(() => {
                            containerRef.value.querySelectorAll('.o365-cell-paste-highlight-animation').forEach(cell => cell.classList.remove('o365-cell-paste-highlight-animation'));
                        }, 1001)
                    }

                });
                return true;
            case `'`:
                _copyFromAbove();
                return true;
            // case 'a':
            // case 'a':
            //     e.preventDefault();
            //     if (selection.value) {
            //         const start = _getFirstRowCell(0);
            //         const end = _getLastRowCell(dataObject.value.data.length - 1)
            //         selection.value.start = start;
            //         selection.value.end = end;
            //         selection.value.renderSelection(containerRef.value);
            //     }
            //     return true;
        }
        return false;
    }

    function _isEventFromPrintableCharacter(e: KeyboardEvent) {
        if (e.altKey || e.ctrlKey || e.metaKey) { return false; }
        const printableCharacter = e.key.length === 1;
        return printableCharacter;
    }

    function _onKeyDown(e: KeyboardEvent) {
        if (e.ctrlKey) {
            const handled = _onKeyDownWithCtrl(e);
            if (handled) { return; }
        }

        if (_isInEditMode()) { return; }

        const target = <HTMLElement>e.target;
        if (!target.classList.contains('o365-body-cell')) { return; }

        if (_isEventFromPrintableCharacter(e)) {
            e.preventDefault();
            //_startEditMode(e.key);
            //TODO: Enter edit mode with value e.key
        }
    }

    const handleKeydown = (e: KeyboardEvent) => {
        const key = e.key;
        switch (key) {
            case KeyCode.ENTER:
                _onEnterKeyDown(e);
                break;
            case KeyCode.F2:
                _onF2KeyDown(e);
                break;
            case KeyCode.ESC:
                _onEscKeyDown(e);
                break;
            case KeyCode.TAB:
                e.preventDefault();
                _onTabKeyDown(e);
                break;
            case KeyCode.DEL:
                break;
            case KeyCode.SPACE:
                break;
            case KeyCode.DOWN:
            case KeyCode.UP:
            case KeyCode.LEFT:
            case KeyCode.RIGHT:
                _onNavigationKeyDown(e, key);
                break;
            default:
                if (!options.forceEditMode) {
                    _onKeyDown(e);
                }
        }
    };

    const handlePaste = (e: KeyboardEvent) => {
        navigation.paste(e).then((success: boolean) => {
            if (success) {
                containerRef.value.querySelectorAll('.o365-cell-range-selected, .o365-cell-range-single-cell').forEach(cell => cell.classList.add('o365-cell-paste-highlight-animation'))
                setTimeout(() => {
                    containerRef.value.querySelectorAll('.o365-cell-paste-highlight-animation').forEach(cell => cell.classList.remove('o365-cell-paste-highlight-animation'));
                }, 1001);
            }
        });
    }

    async function _copyFromAbove() {
        const activeCell = gridFocusControl.activeCellLocation;
        if (!activeCell) { return; }
        const cell = _getCellAbove(activeCell);
        if (_isValidNavigationCell(cell)) {
            navigation.handleCopyFromAbove(cell, activeCell);
        }

    }

    onMounted(() => {
        containerRef.value.addEventListener('keydown', handleKeydown);
        if (options.forceEditMode) {
            containerRef.value.addEventListener('paste', handlePaste);
        }
    });

    onBeforeUnmount(() => {
        containerRef.value.removeEventListener('keydown', handleKeydown);
        if (options.forceEditMode) {
            containerRef.value.removeEventListener('paste', handlePaste);
        }
    });


    const focusFirstEditableCell = async (index = 0) => {
        const cell = _getFirstRowCell(index, true);
        if (cell) {
            gridSelectionInterface.setSingleSelection(cell);
            gridFocusControl.setFoucsToCell(cell);
        }
    };

    return { focusFirstEditableCell };
}

/**
 * Enables selection rendering
 */
export function useSelectionRendering(options: {
    containerRef: Ref<HTMLElement>,
    gridControl: Ref<BaseGridControl>,
    rowSelector: string,
    cellSelector: string
}, NavigationExtension: typeof BaseGridNavigation) {
    const gridSelectionInterface = GridSelectionInterface.getGridSelectionInterface(options.gridControl);
    const navigation = NavigationExtension.extend(options.gridControl.value);

    function _clearClassBind() {
        gridSelectionInterface.selectionClassMap = null;
    }

    function renderSelection(newSelection: AreaSelection) {
        window.requestAnimationFrame(() => {
            _clearClassBind();

            if (!newSelection) { return; }

            const range = (start: number, stop: number, step = 1) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
            const negativeCols = newSelection.end.x - newSelection.start.x < 0;
            const negativeRows = newSelection.end.y - newSelection.start.y < 0;

            const columns = range(newSelection.start.x, newSelection.end.x, negativeCols ? -1 : 1);
            const rows = range(newSelection.start.y, newSelection.end.y, negativeRows ? -1 : 1);

            const topRow = negativeRows ? rows[rows.length - 1] : rows[0];
            const bottomRow = negativeRows ? rows[0] : rows[rows.length - 1];
            const leftCol = negativeCols ? columns[columns.length - 1] : columns[0];
            const rightCol = negativeCols ? columns[0] : columns[columns.length - 1];
            rows.forEach((row) => {
                columns.forEach((col) => {
                    if (!navigation.validRowIndex(row)) { return; }

                    const classMap = ['o365-cell-range-selected'];

                    if (topRow === row) {
                        classMap.push('o365-cell-range-top')
                    }
                    if (bottomRow === row) {
                        classMap.push('o365-cell-range-bottom')
                    }
                    if (leftCol === col) {
                        classMap.push('o365-cell-range-left')
                    }
                    if (rightCol === col) {
                        classMap.push('o365-cell-range-right')
                    }

                    if (!gridSelectionInterface.selectionClassMap) { gridSelectionInterface.selectionClassMap = {}; }
                    if (!gridSelectionInterface.selectionClassMap[row]) { gridSelectionInterface.selectionClassMap[row] = {}; }
                    gridSelectionInterface.selectionClassMap[row][col] = classMap;
                });
            });
        });
    }

    watch(() => navigation.selectionControl.selection, (newSelection: AreaSelection, _prevSelection: AreaSelection) => {
        renderSelection(newSelection);
    });

    return { renderSelection };
}

/**
 * Enables focus rendering and active editing cell switching
 */
export function useGridFocusRendering(options: {
    containerRef: Ref<HTMLElement>,
    gridControl: Ref<BaseGridControl>,
    rowSelector: string,
    cellSelector: string,
    getCellEditorRef?: Function,
    forceEditMode?: boolean
}, NavigationExtension: typeof BaseGridNavigation) {
    const gridFocusControl = GridFocusControl.getGridFocusControl(options.gridControl);
    const navigation = NavigationExtension.extend(options.gridControl.value);

    function _activateEditor(cellNode?: any) {
        nextTick().then(() => {
            if (options.getCellEditorRef) {
                options.getCellEditorRef().then((cellEditorRef) => {
                    const findFunction = (referance: any) => {
                        if (!referance) { return; }
                        else if (referance.activateEditor) { return referance.activateEditor; }
                        else if (referance.editorRef) { return findFunction(referance.editorRef); }
                        else if (referance.$?.subTree?.component?.ctx) { return findFunction(referance.$.subTree.component.ctx); }
                        else if (referance?.nextElementSibling?.__vnode?.ctx?.exposed?.activateEditor) { return referance.nextElementSibling.__vnode.ctx.exposed.activateEditor; }
                        else if (referance?.nextElementSibling?.tagName === 'INPUT') { referance.nextElementSibling.click(); referance.nextElementSibling.focus(); return; }
                        else if (referance?.parentElement?.querySelector('input')) { referance.parentElement.querySelector('input').click(); referance.parentElement.querySelector('input').focus(); return; }
                        else if (referance?.parentElement?.querySelector('textarea')) { referance.parentElement.querySelector('textarea').click(); referance.parentElement.querySelector('textarea').focus(); return; }
                        else { return; }
                    };
                    let activate = findFunction(cellEditorRef.value?.$refs?.editorRef);
                    if (activate) { activate(); }
                });
            } else if (cellNode) {
                if (gridFocusControl.focusedThroughClick) { gridFocusControl.focusedThroughClick = false; return; }
                cellNode.click();
                nextTick().then(() => {
                    const findFunction = (referance: any) => {
                        if (!referance) { return; }
                        else if (referance.activateEditor) { return referance.activateEditor; }
                        else if (referance.$refs?.popupRef) { return findFunction(referance.$refs.popupRef); }
                        else if (referance.$refs?.editorRef) { return findFunction(referance.$refs.editorRef); }
                        else if (referance.editorRef) { return findFunction(referance.editorRef); }
                        else if (referance.$?.subTree?.component?.ctx) { return findFunction(referance.$.subTree.component.ctx); }
                        else if (referance.parentElement) {
                            let element = referance.parentElement.querySelector('input');
                            if (element) { element.click(); element.focus(); return; }
                            element = referance.parentElement.querySelector('textarea');
                            if (element) { element.click(); element.focus(); return; }
                            element = referance.parentElement.querySelector('select');
                            if (element) { element.click(); element.focus(); return; }
                        }
                        else { return; }
                    };
                    let activate = findFunction(cellNode.__vnode?.ctx?.ctx);
                    if (activate) { 
                        activate();
                    }
                });
            }
        });
    }

    if (!options.forceEditMode) {
        watch(() => gridFocusControl.editMode, (newValue: boolean) => {
            if (newValue) {
                _activateEditor();
            }
        });
    }

    let currentIndexDebounce: any;
    watch(() => gridFocusControl.activeCell, (newValue: any) => {
        if (currentIndexDebounce) { window.clearTimeout(currentIndexDebounce); }
        options.containerRef.value.querySelectorAll('.o365-focus-cell').forEach(element => element.classList.remove('o365-focus-cell'));
        if (!newValue) { return; }
        const cell = gridFocusControl.activeCellLocation;
        const cellNode = <HTMLElement>options.containerRef.value.querySelector(getCellSelector(options.rowSelector, options.cellSelector, cell.y, cell.x));
        if (cellNode) {
            cellNode.classList.add('o365-focus-cell');
            cellNode.focus();

            if (options.forceEditMode) {
                _activateEditor(cellNode);
            } else if (gridFocusControl.editMode) {
                _activateEditor();
            } else {
                // if (currentIndexDebounce) { window.clearTimeout(currentIndexDebounce); currentIndexDebounceLength = 100; }
                currentIndexDebounce = window.setTimeout(() => {
                    navigation.setCurrentIndex(cell.y);
                    currentIndexDebounce = null;
                }, 500);
            }
        }
    });
}

/**
 * Enables selection and edit mode mouse events.
 * Drag select is optional
 */
export function useSelectionMouseEvents(options: {
    containerRef: Ref<HTMLElement>,
    gridControl: Ref<BaseGridControl>,
    enableDrag?: boolean,
    forceEditMode?: boolean,
    rowSelector: string,
    cellSelector: string,
    enableContextMenu?: boolean,
}) {
    const container = options.containerRef;
    const gridSelectionInterface = GridSelectionInterface.getGridSelectionInterface(options.gridControl);
    const gridFocusControl = GridFocusControl.getGridFocusControl(options.gridControl);

    function _isInEditMode() {
        return options.forceEditMode || gridFocusControl.editMode;
    }

    function getClosestCellFromEvent(e: MouseEvent) {
        const target = <HTMLElement>e.target;
        const vClosest = <HTMLElement>target.closest(options.cellSelector);
        return vClosest;
    }

    let isDragging = false;
    const handleMouseDown = (e: MouseEvent) => {
        if (e.button !== 0) { return; }
        const vClosest = getClosestCellFromEvent(e)
        if (vClosest && !vClosest.classList.contains('o365-editor-cell')) {
            if (vClosest.getAttribute('o365-field')?.startsWith('o365_')) { return; }

            const col = getColIndex(vClosest);
            const row = getRowIndex(<HTMLElement>vClosest.closest(options.rowSelector));

            const suppressedOrders = gridSelectionInterface.getSelectionSuppressedColumns();
            if (suppressedOrders.includes(col)) {
                if (_isInEditMode()) {
                    gridFocusControl.exitEditMode();
                }
                return;
            }

            gridSelectionInterface.setSingleSelection({ x: col, y: row });
            gridFocusControl.setFoucsToCell({ x: col, y: row })

            if (_isInEditMode()) {
                gridFocusControl.exitEditMode();
            }

            // vClosest.focus();
            if (options.enableDrag) {
                e.preventDefault();
                isDragging = true;
                return false;
            } else {
                gridFocusControl.focusedThroughClick = true;
                window.setTimeout(() => {
                    gridFocusControl.focusedThroughClick = false;
                }, 50);
            }
        }
    }

    const handleDrag = (e: MouseEvent) => {
        if (isDragging) {
            const vClosest = getClosestCellFromEvent(e)
            if (vClosest && !vClosest.classList.contains('o365-editor-cell')) {
                e.preventDefault();

                const col = getColIndex(vClosest);
                const row = getRowIndex(<HTMLElement>vClosest.closest(options.rowSelector));
                const suppressedOrders = gridSelectionInterface.getSelectionSuppressedColumns();
                const isPositiveSelection = gridSelectionInterface.areaStart.x <= col;
                const startX = gridSelectionInterface.areaStart.x;
                const skipSelection = suppressedOrders.some(order => {
                    const isSuppressed = isPositiveSelection
                        ? startX < order && col >= order
                        : startX > order && col <= order;
                    return isSuppressed;
                });
                if (skipSelection) {
                    gridSelectionInterface.setAreaEnd({ x: gridSelectionInterface.areaEnd.x, y: row });
                    return;
                }
                gridSelectionInterface.setAreaEnd({ x: col, y: row });
            }
        }
    }

    const handleMouseUp = () => {
        isDragging = false;
    }

    const handleContextMenu = async (e: MouseEvent) => {
        if (e.ctrlKey) { return; }
        const target = <HTMLElement>e.target;
        if (target?.tagName === 'A') { return; }

        const contextMenu = options.gridControl.value.refs.contextMenu;

        const vClosest = getClosestCellFromEvent(e)
        if (!vClosest || (vClosest && vClosest.classList.contains('o365-editor-cell'))) {
            if (contextMenu?.dropdown.isOpen) {
                contextMenu.dropdown.close();
            }
            return;
        } else if (vClosest.getAttribute('o365-field') === 'o365_Action') { return; }
        e.preventDefault();
        const rowIndex = getRowIndex(<HTMLElement>vClosest.parentElement);
        const colIndex = getColIndex(vClosest);
        const cell = { x: colIndex, y: rowIndex };

        const hasRangeSelection = !!gridSelectionInterface.selection && !gridSelectionInterface.isSingleSelection;
        let resetSelection = !hasRangeSelection;
        if (hasRangeSelection) {
            resetSelection = !gridSelectionInterface.locationIsInSelection(cell)
        }
        if (resetSelection) {
            gridSelectionInterface.setSingleSelection(cell);
            gridFocusControl.setFoucsToCell(cell);

            if (_isInEditMode()) {
                gridFocusControl.exitEditMode();
            }
        }

        if (contextMenu.dropdown.isOpen) {
            await contextMenu.dropdown.close();
        }

        const { x: col, y: row } = gridFocusControl.activeCellLocation;
        // TODO: CONTEXT MENU
        /*
        options.contextMenuRef.value.initItemValues({
            column: options.gridControl.value.columns.columns[col],
            row: options.gridControl.value.dataObject.data[row],
            rowIndex: row
        });
        */
        contextMenu.setLocation(e.x, e.y);
        contextMenu.dropdown.open();
    }

    const handeDoubleClick = (e: MouseEvent) => {
        const vClosest = getClosestCellFromEvent(e);
        if (!vClosest || vClosest?.classList?.contains('o365-editor-cell')) { return; }
        if (vClosest.getAttribute('o365-field')?.startsWith('o365_')) { return; }
        const col = getColIndex(vClosest);
        const column = options.gridControl.value.columns.columns[col];
        let rowIndex: number|null = getRowIndex(<HTMLElement>vClosest.closest(options.rowSelector));
        if (rowIndex === NaN) { console.warn('Could not parse row index in grid'); return; }

        if (column && column.dblclickHandler) {
            e.preventDefault();
            e.stopPropagation();
            if (rowIndex === NaN) { rowIndex = null; }
            column.dblclickHandler(e, column, rowIndex);
        }

        if (vClosest) {
            const cell = { x: col, y: rowIndex };
            gridSelectionInterface.setSingleSelection(cell);
            gridFocusControl.setFoucsToCell(cell);

            if (!_isInEditMode()) {
                gridFocusControl.enterEditMode();
            }

        }
    }

    onMounted(() => {
        container.value.addEventListener('mousedown', handleMouseDown);
        if (options.enableDrag) {
            container.value.addEventListener('mouseover', handleDrag);
            window.addEventListener('mouseup', handleMouseUp);
        }

        if (options.enableContextMenu) {
            container.value.addEventListener('contextmenu', handleContextMenu);
        }

        if (!options.forceEditMode) {
            container.value.addEventListener('dblclick', handeDoubleClick);
        }
    });

    onBeforeUnmount(() => {
        container.value.removeEventListener('mousedown', handleMouseDown);
        if (options.enableDrag) {
            container.value.removeEventListener('mouseover', handleDrag);
            window.removeEventListener('mouseup', handleMouseUp);
        }

        if (options.enableContextMenu) {
            container.value.removeEventListener('contextmenu', handleContextMenu);
        }

        if (!options.forceEditMode) {
            container.value.removeEventListener('dblclick', handeDoubleClick);
        }
    });
}

/**
 * Composable that syncs dataobject.allowUpdate with grid focus contorl
 */
export function useAllowEditWatcher(options: {
    gridControl: Ref<BaseGridControl>,
}, NavigationExtension: typeof BaseGridNavigation) {
    const gridFocusControl = GridFocusControl.getGridFocusControl(options.gridControl);
    const navigation = NavigationExtension.extend(options.gridControl.value);

    watch(navigation.allowEditWatchTarget, (allow: boolean) => {
        if (!allow && gridFocusControl.editMode) {
            gridFocusControl.exitEditMode();
        }
    });
}


//--------------------------------------------------------------------------------------------------

/**
 * Grid control extension responsable for keeping track of currently focused cell and edit mode.
 */
export class GridFocusControl {
    private _gridControl: BaseGridControl;
    activeCell: string|null;
    editMode = false;
    focusedThroughClick = false;

    get activeCellLocation() {
        if (!this.activeCell) { return; }
        return this.stringToCell(this.activeCell);
    }

    static getGridFocusControl(gridControl: Ref<BaseGridControl>) {
        if (!gridControl.value.gridFocusControl) {
            gridControl.value.gridFocusControl = new GridFocusControl(gridControl.value);
        }
        return gridControl.value.gridFocusControl;
    }

    constructor(gridControl: BaseGridControl) {
        this._gridControl = gridControl;
    }

    setFoucsToCell(cell: Location) {
        if (!cell) { return; }
        this.activeCell = this.cellToString(cell);
    }

    clearFocus() {
        this.activeCell = null;
    }

    cellToString(cell: Location) {
        if (!cell) { return null; }
        return `${cell.x}_${cell.y}`;
    }

    stringToCell(cellString: string): Location {
        if (!cellString) { return null; }
        return {
            x: parseInt(cellString.split('_')[0]),
            y: parseInt(cellString.split('_')[1]),
        };
    }

    //--- EDIT MODE LOGUC ---

    enterEditMode() {
        if (!this._gridControl.navigation.allowEdit) { return; }
        const cell = this.activeCellLocation;
        if (cell) {
            if (!this._gridControl.navigation.validEditColIndex(cell.x)) { return; }
            if (!this._gridControl.navigation.validEditRowIndex(cell.y)) { return; }

            this.editMode = true;
        }
    }

    exitEditMode() {
        this.editMode = false;
    }

}

/**
 * Interface between grid control and data object selection control
 */
export class GridSelectionInterface {
    private _gridControl: BaseGridControl;
    /**
     * Debounce used to stop recomputing suppressed columns object when drag selecting
     */
    private _suppresedSelectionDebounce: number|null;
    /**
     * Cached suppressed object to return in the same drag event
     */
    private _suppresedCache: number[];
    private get _selectionControl() { return this._gridControl.navigation.selectionControl; }

    selectionClassMap: any;

    get selection() {
        return this._selectionControl.selection;
    }

    get areaStart() {
        return this._selectionControl.selection?.start;
    }

    get areaEnd() {
        return this._selectionControl.selection?.end;
    }

    get isSingleSelection() {
        return this.selection && this.equalLocations(this.areaStart, this.areaEnd);
    }

    static getGridSelectionInterface(gridControl: Ref<BaseGridControl>): GridSelectionInterface {
        if (!gridControl.value.gridSelectionInterface) {
            gridControl.value.gridSelectionInterface = new GridSelectionInterface({ gridControl: gridControl });
        }
        return gridControl.value.gridSelectionInterface;
    }

    constructor(options: { gridControl: Ref<BaseGridControl> }) {
        this._gridControl = options.gridControl.value;
    }

    equalLocations(a: Location, b: Location) {
        return a.x === b.x && a.y === b.y;
    }

    locationIsInSelection(cell: Location) {
        if (!cell) { return false; }
        const xPositive = this.areaEnd.x - this.areaStart.x >= 0;
        const yPositive = this.areaEnd.y - this.areaStart.y >= 0;

        const xIsBetween = xPositive
            ? this.areaStart.x <= cell.x && cell.x <= this.areaEnd.x
            : this.areaEnd.x <= cell.x && cell.x <= this.areaStart.x;

        const yIsBetween = yPositive
            ? this.areaStart.y <= cell.y && cell.y <= this.areaEnd.y
            : this.areaEnd.y <= cell.x && cell.y <= this.areaStart.y;

        return xIsBetween && yIsBetween;
    }

    //--- Location Setters ---
    setSingleSelection(cell: Location) {
        if (!cell) { return; }
        if (!this._selectionControl.selection || !(this.equalLocations(this._selectionControl.selection.start, cell) && this.equalLocations(this._selectionControl.selection.end, cell))) {
            this._selectionControl.selection = { start: cell, end: cell };
        }
    }

    setAreaSelection(area: AreaSelection) {
        if (!area) { return; }
        this._selectionControl.selection = area;
    }

    setAreaStart(cell: Location) {
        if (!cell) { return; }
        this._selectionControl.selection.start = cell;
    }

    setAreaEnd(cell: Location) {
        if (!cell) { return; }
        if (!this.equalLocations(this._selectionControl.selection.end, cell)) {
            this._selectionControl.selection = {
                start: this._selectionControl.selection.start,
                end: cell
            };
        }
    }

    clearSelection() {
        this._selectionControl.selection = null;
    }

    /**
     * Returns object where each key is a suppressed column order
     */
    getSelectionSuppressedColumns(): number[] {
        if (this._suppresedSelectionDebounce) {
            this._setSuppressedSelectionDebounce();
            return this._suppresedCache;
        }

        const suppressedOrders: number[] = [];
        this._gridControl.columns.columns.forEach(col => {
            if (col.suppressSelection) {
                suppressedOrders.push(col.order);
            }
        });
        this._suppresedCache = suppressedOrders;
        this._setSuppressedSelectionDebounce();
        return suppressedOrders;
    }

    /**
     * Reset the suppressed seleciton cache debounce
     */
    private _setSuppressedSelectionDebounce() {
        if (this._suppresedSelectionDebounce) { window.clearTimeout(this._suppresedSelectionDebounce); }
        this._suppresedSelectionDebounce = window.setTimeout(() => {
            this._suppresedSelectionDebounce = null;
        }, 50);
    };
}

function getRowIndex(element: HTMLElement) {
    const row = element.dataset.o365Rowindex;
    return parseInt(row ?? '');
}

function getColIndex(element: HTMLElement) {
    const col = element.dataset.o365Colindex;
    return parseInt(col ?? '');
}

function getCellSelector(rowSelector: string, cellSelector: string, y: number, x: number) {
    return `${rowSelector}[data-o365-rowindex="${y}"] ${cellSelector}[data-o365-colindex="${x}"]`;
}